<template>
    <div class="main">
        <Title > 价值评估</Title>
        <div class="col8 col6-4" style="display:flex;flex-wrap:wrap;margin:0 auto">
            <div class="col6" style="padding: 10px;
    box-sizing: border-box;
    text-align: left;
    text-indent: 2rem;
    letter-spacing: 2px;
    line-height: 1.7rem;
    margin-bottom: 1rem;">
                <p class=" wow slideInLeft">
                    历思可为案件办理、市场交易等提供专业的价格与价值评估服务。

                </p>
                <p class=" wow slideInLeft">
                    目前旗下厦门历思评估服务有限公司评估对象主要包括珠宝玉石、贵金属、艺（美）术品、收藏品及各类涉案财物等。

                </p>
            </div>
            <div class="col4 wow slideInRight">
                <ImagePreview class="s_img" :src="require('../../assets/Domain/KS/1.jpg')" :srcList="[require('../../assets/Domain/KS/1.jpg')]"></ImagePreview>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    computed:{
    },
}
</script>
<style src="../../layout.css" scoped></style>
<style scoped>
.main{
    width:80vw;
    margin:0 auto;
    margin-bottom:3rem;
    max-width:1600px;
}
.img_s{
    min-width:300px;
    width:25vw;
    max-width:350px;
    margin:0 auto;
    max-height: 300px;
}
.img_box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.img8{
  width:100%;
  height:100%
}
.img{
    min-width:300px;
    width:30vw;
    max-width:400px;
    margin:0 auto;
    height: 22vw;
    max-height: 281px;
    margin-bottom:2rem;
    min-height: 200px;
}
.box{
    margin:3rem 0;
    display:flex;
    justify-content:space-around;
    flex-wrap:wrap;
}
.left{
    width:60%;
    min-width:300px;
    padding-right:20px;
}
/deep/.left .p{
    margin:0;
    font-size:
}
@media screen and ( max-width:1200px){
    .left{
        width:100%;
        margin-bottom:2rem;
    }
    
}
</style>